<template>
  <div>
    <Modal>
      <div class="title">Aprobación por comité viejo</div>
      <div v-if="solicitud_vieja" class="body">
        <div class="row">
          <div class="col-sm-12">
            <div :class="'row mb-3 solicitud_resolucion '+(solicitud_vieja.estado_id == 4 ? 'resolucion_aprobada_bg' : 'resolucion_rechazada_bg')">
              <div class="col-sm-1">Resolución</div>
              <div :class="'col-sm-11 text-right '+(solicitud_vieja.estado_id == 4 ? 'resolucion_aprobada' : 'resolucion_rechazada')">{{ solicitud_vieja.estado_id == 4 ? 'Aprobada' : 'Rechazada' }}</div>
            </div>

            <div class="row bloque bloque_informacion">
              <div class="col-sm-12 titulo">Información de la solicitud</div>
              <div class="col-sm-12 col-lg-6">
                <div class="row">
                  <div class="col-sm-3 cabecera">ID Solicitud</div>
                  <div class="col-sm-8 valor">{{ solicitud_vieja.id }}</div>
                </div>
                <div class="row">
                  <div class="col-sm-3 cabecera">Monto</div>
                  <div class="col-sm-8 valor">{{ solicitud_vieja.autorizado_comite ? '$'+$helper.moneyFormat(solicitud_vieja.autorizado_comite) : '--' }}</div>
                </div>
                <div class="row">
                  <div class="col-sm-3 cabecera">Tasa interés</div>
                  <div class="col-sm-8 valor">{{ solicitud_vieja.tasa_interes ? solicitud_vieja.tasa_interes+'%' : '--' }}</div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6">
                <div class="row">
                  <div class="col-sm-3 cabecera">Aforo</div>
                  <div class="col-sm-8 valor">{{ solicitud_vieja.aforo ? solicitud_vieja.aforo : '--' }}</div>
                </div>
                <div class="row">
                  <div class="col-sm-3 cabecera">Plazo</div>
                  <div class="col-sm-8 valor">{{ solicitud_vieja.plazo ? solicitud_vieja.plazo+' meses' : '--' }}</div>
                </div>
              </div>
            </div>

            <div class="row bloque bloque_votos">
              <div class="col-sm-12 titulo">Votos</div>
              <div v-for="voto in solicitud_vieja.votos" class="col-sm-12 bloque_voto">
                <div class="row">
                  <div class="col-sm-12 col-lg-6">
                    <div class="row">
                      <div class="col-sm-4 cabecera">Nombre:</div>
                      <div class="col-sm-8 valor">{{ voto.acreditador.nombre }}</div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-sm-4 cabecera">Aforo:</div>
                      <div class="col-sm-8 valor">{{ voto.aforo ? voto.aforo+' / 1' : '--' }}</div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-sm-4 cabecera">Monto propuesto:</div>
                      <div class="col-sm-8 valor">{{ voto.autorizado ? '$'+$helper.moneyFormat(voto.autorizado) : '--' }}</div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <div class="row">
                      <div class="col-sm-4 cabecera">Resolución:</div>
                      <div class="col-sm-8 valor voto_resolucion"><span :class="voto.estado_id == 4 ? 'resolucion_aprobada' : 'resolucion_rechazada'">{{ voto.estado_id == 4 ? 'Aprobada' : 'Rechazada' }}</span></div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-sm-4 cabecera">Plazo:</div>
                      <div class="col-sm-8 valor">{{ voto.plazo ? (voto.plazo < 12 ? (voto.plazo * 12)+' meses' : voto.plazo+' meses') : '--' }}</div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-sm-4 cabecera">Tasa interés:</div>
                      <div class="col-sm-8 valor">{{ voto.tasa_interes ? voto.tasa_interes+' %' : '--' }}</div>
                    </div>
                  </div>
                    <div class="col-sm-2 cabecera">Observación:</div>
                    <div class="col-sm-10 valor">{{ voto.observacion ? voto.observacion : '--' }}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div v-else class="body">
        <div class="row">
          <div class="col-sm-12 no-existe">
            La solicitud {{ solicitud.solicitud_id }} no existe en el comité viejo.
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button v-if="solicitud_vieja" class="btn btn-primary mr-2" @click="resolucion_viejo">Agregar resolución a solicitud</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/analyticpoint/api/antiguo/solicitudes';
import apiSolicitud from '@/apps/analyticpoint/api/comite/solicitudes';

export default {
  components: {
    Modal
  },
  props: {
    solicitud: {
      type: Object,
      required: true,
      default() {
        return {
          solicitud_id: 23489
        }
      }
    }
  },
  data() {
    return {
      solicitud_vieja: null,
    }
  },
  mounted() {
    this.obtener_solicitud();
  },
  methods: {
    async obtener_solicitud() {
      try {
        this.solicitud_vieja = (await api.listar_solicitud(this.solicitud.solicitud_id)).data;
      }catch(e) {
        this.$log.error('error',e);
        // this.$helper.showAxiosError(e,'Error');
      }
    },
    async resolucion_viejo() {
      try {
        let res = (await apiSolicitud.resolucion_vieja(this.solicitud.id)).data;
        this.$log.info('res', res);
        this.$emit('update',true);
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .solicitud_resolucion {
    font-size: 1.5em;
    font-weight: bold;
  }

  .resolucion_aprobada_bg,.resolucion_rechazada_bg {
    padding: 10px 15px;
  }

  .resolucion_aprobada_bg {
    background-color: hsl(120, 69%, 91%);
  }

  .resolucion_rechazada_bg {
    background-color: rgb(241, 183, 183);
  }

  .resolucion_aprobada {
    color: rgb(0, 104, 0);
  }

  .resolucion_rechazada {
    color: rgb(172, 2, 2);
  }

  .bloque {
    margin-bottom: 20px;

    .titulo {
      background-color: hsl(0, 0%, 30%);
      color: #fff;
      font-size: 1.3em;
      text-align: right;
      margin-bottom: 10px;
      padding: 10px 15px;
    }

    .cabecera, .valor {
      padding: 5px 10px;
      margin-bottom: 5px;
    }

    .cabecera {
      background-color: hsl(0, 0%, 85%);
    }

    .valor {
      background-color: hsl(0, 0%, 95%);
    }

    .voto_resolucion {
      font-weight: bold;
    }

    .bloque_voto {
      margin-bottom: 20px;
    }
  }
  .no-existe {
    font-size: 1.8em;
    font-weight: bold;
    text-align: center;
  }
</style>