import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_ANALYTICPOINT+'/antiguo/solicitudes');

export default {
  listar_solicitudes() {
    return http.get('/');
  },

  listar_solicitud(id) {
    return http.get(`/${id}?relations[0]=votos.acreditador`);
  }
}